import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide, Fade } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

//import ContactForm from "../components/form/contact";

// images
import banner_img from "../images/dokumenttolkning.svg";
import pdf_tolkning from "../images/pdf_tolkning.png";
import icon_1 from "../images/icons/fordelar_1.svg";
import icon_2 from "../images/icons/fordelar_2.svg";
import icon_3 from "../images/icons/fordelar_3.svg";
import icon_4 from "../images/icons/fordelar_4.svg";
import icon_5 from "../images/icons/fordelar_5.svg";

const KONTAKT_BOT_CODE = "NUbkN9AF";
const DOCUMENT_BOT_CODE = "Lk0hDS2F";

function Dokument() {
    const [chatbot_1, setChatbot_1] = useState(false);
    const [chatbot_2, setChatbot_2] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Dokumenttolkning | Optosweden</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Optosweden är certifierade på Abbyy Flexicapture.  Vi kan hantera alla typer av dokument, från enkla former till komplexa friformsdokument." />
                <meta name="keywords" content="dokumentbehandlingsplattform | ABBYY  | FlexiCapture " />
                <meta property="og:title" content="Optosweden AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Optosweden är certifierade på Abbyy Flexicapture.  Vi kan hantera alla typer av dokument, från enkla former till komplexa friformsdokument." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.optosweden.se/dokument" />
                <link rel="cannonical" href="https://www.optosweden.se/dokument" />
            </Helmet>



            {/***********  Hero Section - START  **********/}
            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">                           
                            <Slide left>
                            <div className="flex flex-col items-start justify-center">
                                <h1 className="title-font font-semibold text-3xl text-blue-custome">ABBYY FlexiCapture</h1>
                                <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                    OptoSweden är stolta över att vara certifierade i 
                                    Norden på Abbyy Flexicapture. Med Abbyy Flexicapture 
                                    kan vi hjälpa er att bygga lösningar för 
                                    dokument tolkning i molnet eller inhouse.
                                </p>
                                <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                {chatbot_1 
                                    ? 
                                        <div className="h-full w-full mt-10">
                                            <InPageBot code={KONTAKT_BOT_CODE} seamless/>
                                        </div> 
                                    : "" 
                                }
                            </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 p-4 mx-auto opacity-90">
                                    <img className="w-full" src={banner_img} alt="dokumenttolkning" />
                                </div>
                            </Slide>

                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>
            {/***********  Hero Section - END  **********/}



            <section className="bg-gray-100" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="pb-10 flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">dokumenttolkning</span>
                    </div>
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-2/3 p-4 mx-auto">
                            <img className="w-full" src={pdf_tolkning} alt="PaperStream Capture Pro" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Så vad är Abbyy Flexicapture?</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                ABBYY FlexiCapture är en intelligent dokumentbehandlingsplattform 
                                byggd för att tillgodose behoven hos dagens komplexa digitala 
                                företag. FlexiCapture samlar de bästa NLP-, maskininlärnings- 
                                och avancerade igenkänningsfunktionerna i en enda plattform 
                                för att hantera alla typer av dokument, från enkla former 
                                till komplexa friformsdokument och i alla storlekar, från 
                                ad hoc-enskilda dokument till stora batchjobb som kräver 
                                tuffa SLA. Genom att organisera processen från förvärv till 
                                leverans matar FlexiCapture innehållsdrivna affärsapplikationer 
                                som RPA och BPM, vilket hjälper organisationer att fokusera 
                                på kundservice, kostnadsminskning, efterlevnad 
                                och konkurrensfördelar.
                                <br/><br/>
                                Låt oss hjälpa dig i din resa in i digitaliseringen. 
                                Vi har verktygen, kunskapen och ett bevisat track 
                                record med stora offentliga kunder så som 
                                privata mindre enkla lösningar.
                            </p>
                            <button onClick={() => setChatbot_2(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                            {chatbot_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={KONTAKT_BOT_CODE} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                    </div>
                </div>
            </section>






            <section className="bg-gray-100 pt-24 pb-16">
                <div className="container mx-auto px-4 xl:px-24">
                    <hr className="text-gray-200"/>
                </div>
            </section>




            {/***********  Funktioner & fördelar - START  **********/}
            <section className="bg-gray-100 py-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <h1 className="title-font font-semibold text-3xl text-gray-900">Funktioner &amp; fördelar</h1>
                    <p className="leading-relaxed mt-10 text-lg text-gray-600">
                        Företagsautomation börjar med en omfattande plattform 
                        för att skaffa, bearbeta, validera och leverera 
                        rätt data till kritiska processer.
                    </p>
                    <div className="mt-12 grid  lg:grid-cols-2 gap-16">
                        <Fade>
                            <div className="p-4 flex justify-start items-start rounded-lg bg-gradient-to-b from-white to-gray-100">
                                <img className="w-20"src={icon_1} alt=""/>
                                <div className="ml-4">
                                    <h3 className="font-semibold text-lg text-gray-800">Smidiga transaktioner, smarta beslut och snabba åtgärder</h3>
                                    <p className="mt-4 text-gray-600">
                                        Utnyttja kundinformation som tillhandahålls för att påskynda 
                                        transaktioner, fatta smartare beslut och ge snabba, 
                                        korrekta svar till dina kunder.
                                    </p>
                                </div>
                            </div>
                        </Fade>
                        <Fade>
                            <div className="p-4 flex justify-start items-start rounded-lg bg-gradient-to-b from-white to-gray-100">
                                <img className="w-20"src={icon_2} alt=""/>
                                <div className="ml-4">
                                    <h3 className="font-semibold text-lg text-gray-800">Datavalidering och kontroll</h3>
                                    <p className="mt-4 text-gray-600">
                                        Kritiska datafält, viktigt information 
                                        och dokumentets identitet identifieras, valideras och 
                                        bearbetas automatiskt enligt affärsregler och krav. 
                                        Systemet kan lätt tränas och använder kontinuerlig maskininlärning 
                                        för kontinuerliga förbättringar och kostnadskontroll.
                                    </p>
                                </div>
                            </div>
                        </Fade>
                        <Fade>
                            <div className="p-4 flex justify-start items-start rounded-lg bg-gradient-to-b from-white to-gray-100">
                                <img className="w-20"src={icon_3} alt=""/>
                                <div className="ml-4">
                                    <h3 className="font-semibold text-lg text-gray-800">Kontroll, förutsägbarhet och krav</h3>
                                    <p className="mt-4 text-gray-600">
                                        Få en fullständig kedja av anpassad rapportering och hantering 
                                        för finjustering av resultaten, samtidigt som du säkerställer 
                                        att du följer de krav som ställs på dig som företag från 
                                        början till slut.
                                    </p>
                                </div>
                            </div>
                        </Fade>
                        <Fade>
                            <div className="p-4 flex justify-start items-start rounded-lg bg-gradient-to-b from-white to-gray-100">
                                <img className="w-20"src={icon_4} alt=""/>
                                <div className="ml-4">
                                    <h3 className="font-semibold text-lg text-gray-800">Snabbare hantering</h3>
                                    <p className="mt-4 text-gray-600">
                                        Innehåll från dokument som kommer in via valfri kanal, 
                                        i vilket format som helst, extraheras, förstås och 
                                        levereras automatiskt, vilket tar bort manuell friktion.
                                    </p>
                                </div>
                            </div>
                        </Fade>
                        <Fade>
                            <div className="p-4 flex justify-start items-start rounded-lg bg-gradient-to-b from-white to-gray-100">
                                <img className="w-20"src={icon_5} alt=""/>
                                <div className="ml-4">
                                    <h3 className="font-semibold text-lg text-gray-800">Intelligent datautvinning</h3>
                                    <p className="mt-4 text-gray-600">
                                        Genom att utnyttja Natural Language Processing (NLP) -teknologi 
                                        kan du nu automatisera identifiering och extrahering av data från 
                                        ostrukturerade, komplexa dokument som kontrakt och e-post, 
                                        tillsammans med strukturerade och halvstrukturerade dokument, 
                                        vilket hjälper till att påskynda transaktioner samtidigt som 
                                        hanterings kostnaderna och felen avsevärt minskar.
                                    </p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                
                    <div className="w-full lg:w-2/3 mt-16">
                        <InPageBot code={DOCUMENT_BOT_CODE}/>
                    </div>
                    
                </div>
            </section>
            {/***********  Funktioner & fördelar - END  **********/}



            <section className="py-32">
                {/*-------------- other services - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
                        <div className="px-8 md:px-0">
                            <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"/>
                                    <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>    
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Skanners</h2>
                            <p className="font-light text-sm text-gray-500">
                                På OptoSweden har vi över 20 års erfarenhet av skanningslösningar, 
                                där vi som återförsäljare av flera marknadsledande varumärken har 
                                kompetensen och möjligheterna att guida dig rätt
                            </p>
                            <Link to="/skanners" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        <div className="px-8 md:px-0">
                            <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Skanningsprogramvara</h2>
                            <p className="font-light text-sm text-gray-500">
                                Hos oss kan ni välja den programvara för bildfångst som passar just 
                                era behov. Samtidigt kan ni vara säkra på att ni får hjälp i 
                                ert val från våra kunniga och erfarna säljare, tekniker och konsulter.
                            </p>
                            <Link to="/skanningsprogramvara" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>

                        <div className="px-8 md:px-0">
                            <div className="bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-custome shadow-2xl">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>    
                                </svg>
                            </div>
                            <h2 className="uppercase mt-6 text-blue-custome font-medium mb-3">Service &amp; Support</h2>
                            <p className="font-light text-sm text-gray-500">
                                Vi hjälper dig! Vår service-och supportavdelning hjälper er 
                                med företagets skanner och mjukvara. Vi erbjuder service 
                                och support men även utbildning på er skanner och mjukvara 
                                i hela Norden.
                            </p>
                            <Link to="/service" className="mt-3 text-blue-custome flex items-center hover:text-blue-600">
                                Läs mer
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"  fill="currentColor">
                                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                </svg>
                            </Link>
                        </div>
                    </div> 
                </div> 
                {/*-------------- other services - END ---------------*/}
            </section>
        </Layout>
    );
}
export default Dokument;